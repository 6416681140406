<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="512"
    height="512"
  >
    <path
      d="M12.561,8.439l3.293,3.293-.707,.707-3.146-3.146v7.707h-1v-7.707l-3.146,3.146-.707-.707,3.293-3.293c.585-.584,1.536-.584,2.121,0Zm11.439,5.561c0,3.859-3.141,7-7,7H4.75c-2.619,0-4.75-2.131-4.75-4.75,0-1.627,.827-3.125,2.181-3.996-.12-.574-.181-1.163-.181-1.754C2,5.813,5.813,2,10.5,2c3.421,0,6.453,2.001,7.803,5.121,3.268,.616,5.697,3.523,5.697,6.879Zm-1,0c0-2.959-2.204-5.511-5.126-5.937l-.287-.042-.105-.27c-1.139-2.887-3.879-4.752-6.981-4.752-4.136,0-7.5,3.364-7.5,7.5,0,.635,.079,1.265,.236,1.874l.098,.38-.346,.186c-1.226,.654-1.987,1.924-1.987,3.311,0,2.067,1.682,3.75,3.75,3.75h12.25c3.309,0,6-2.691,6-6Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cloudUploadAlt'
};
</script>

<style lang="scss" scoped>
svg {
  fill: #704ad1;
  overflow: hidden;
  vertical-align: middle;
  transform: rotateZ(0deg);
  height: 25px;
  width: 25px;
}
</style>
